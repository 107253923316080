exports.components = {
  "component---src-pages-404-index-en-js": () => import("./../../../src/pages/404/index.en.js" /* webpackChunkName: "component---src-pages-404-index-en-js" */),
  "component---src-pages-404-index-es-js": () => import("./../../../src/pages/404/index.es.js" /* webpackChunkName: "component---src-pages-404-index-es-js" */),
  "component---src-pages-about-us-index-en-js": () => import("./../../../src/pages/about-us/index.en.js" /* webpackChunkName: "component---src-pages-about-us-index-en-js" */),
  "component---src-pages-about-us-index-es-js": () => import("./../../../src/pages/about-us/index.es.js" /* webpackChunkName: "component---src-pages-about-us-index-es-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../../../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-es-js": () => import("./../../../src/pages/contact/index.es.js" /* webpackChunkName: "component---src-pages-contact-index-es-js" */),
  "component---src-pages-cookies-policy-index-en-js": () => import("./../../../src/pages/cookies-policy/index.en.js" /* webpackChunkName: "component---src-pages-cookies-policy-index-en-js" */),
  "component---src-pages-cookies-policy-index-es-js": () => import("./../../../src/pages/cookies-policy/index.es.js" /* webpackChunkName: "component---src-pages-cookies-policy-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-challenge-test-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-challenge-test/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-challenge-test-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-challenge-test-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-challenge-test/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-challenge-test-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-product-information-file-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-product-information-file/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-product-information-file-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-product-information-file-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-product-information-file/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-product-information-file-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-responsible-person-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-responsible-person/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-responsible-person-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-responsible-person-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-responsible-person/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-responsible-person-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-safety-assessment-cpsr-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-safety-assessment-cpsr-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-services-cosmetics-safety-assessment-cpsr-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cosmetics-safety-assessment-cpsr-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-services-cpnp-notification-scpn-notification-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cpnp-notification-scpn-notification/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cpnp-notification-scpn-notification-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-services-cpnp-notification-scpn-notification-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory-services/cpnp-notification-scpn-notification/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-cpnp-notification-scpn-notification-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-services-product-classification-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory-services/product-classification/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-product-classification-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-services-product-classification-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory-services/product-classification/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-product-classification-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-services-product-labelling-and-claims-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory-services/product-labelling-and-claims/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-product-labelling-and-claims-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-services-product-labelling-and-claims-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory-services/product-labelling-and-claims/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-product-labelling-and-claims-index-es-js" */),
  "component---src-pages-cosmetics-regulatory-services-stability-compatibility-test-index-en-js": () => import("./../../../src/pages/cosmetics-regulatory-services/stability-compatibility-test/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-stability-compatibility-test-index-en-js" */),
  "component---src-pages-cosmetics-regulatory-services-stability-compatibility-test-index-es-js": () => import("./../../../src/pages/cosmetics-regulatory-services/stability-compatibility-test/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-regulatory-services-stability-compatibility-test-index-es-js" */),
  "component---src-pages-cosmetics-testing-anti-aging-index-en-js": () => import("./../../../src/pages/cosmetics-testing/anti-aging/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-aging-index-en-js" */),
  "component---src-pages-cosmetics-testing-anti-aging-index-es-js": () => import("./../../../src/pages/cosmetics-testing/anti-aging/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-aging-index-es-js" */),
  "component---src-pages-cosmetics-testing-anti-dandruff-index-en-js": () => import("./../../../src/pages/cosmetics-testing/anti-dandruff/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-dandruff-index-en-js" */),
  "component---src-pages-cosmetics-testing-anti-dandruff-index-es-js": () => import("./../../../src/pages/cosmetics-testing/anti-dandruff/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-dandruff-index-es-js" */),
  "component---src-pages-cosmetics-testing-anti-wrinkle-index-en-js": () => import("./../../../src/pages/cosmetics-testing/anti-wrinkle/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-wrinkle-index-en-js" */),
  "component---src-pages-cosmetics-testing-anti-wrinkle-index-es-js": () => import("./../../../src/pages/cosmetics-testing/anti-wrinkle/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-anti-wrinkle-index-es-js" */),
  "component---src-pages-cosmetics-testing-brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease-index-en-js": () => import("./../../../src/pages/cosmetics-testing/brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease-index-en-js" */),
  "component---src-pages-cosmetics-testing-brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease-index-es-js": () => import("./../../../src/pages/cosmetics-testing/brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-brightening-pigmentation-evaluation-skin-lightening-skin-whitening-dark-spots-decrease-index-es-js" */),
  "component---src-pages-cosmetics-testing-cellulite-reduction-anti-stretch-marks-index-en-js": () => import("./../../../src/pages/cosmetics-testing/cellulite-reduction-anti-stretch-marks/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cellulite-reduction-anti-stretch-marks-index-en-js" */),
  "component---src-pages-cosmetics-testing-cellulite-reduction-anti-stretch-marks-index-es-js": () => import("./../../../src/pages/cosmetics-testing/cellulite-reduction-anti-stretch-marks/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cellulite-reduction-anti-stretch-marks-index-es-js" */),
  "component---src-pages-cosmetics-testing-cleansing-index-en-js": () => import("./../../../src/pages/cosmetics-testing/cleansing/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cleansing-index-en-js" */),
  "component---src-pages-cosmetics-testing-cleansing-index-es-js": () => import("./../../../src/pages/cosmetics-testing/cleansing/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cleansing-index-es-js" */),
  "component---src-pages-cosmetics-testing-cosmetics-patch-test-index-en-js": () => import("./../../../src/pages/cosmetics-testing/cosmetics-patch-test/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cosmetics-patch-test-index-en-js" */),
  "component---src-pages-cosmetics-testing-cosmetics-patch-test-index-es-js": () => import("./../../../src/pages/cosmetics-testing/cosmetics-patch-test/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-cosmetics-patch-test-index-es-js" */),
  "component---src-pages-cosmetics-testing-eye-puffiness-dark-circles-index-en-js": () => import("./../../../src/pages/cosmetics-testing/eye-puffiness-dark-circles/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-eye-puffiness-dark-circles-index-en-js" */),
  "component---src-pages-cosmetics-testing-eye-puffiness-dark-circles-index-es-js": () => import("./../../../src/pages/cosmetics-testing/eye-puffiness-dark-circles/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-eye-puffiness-dark-circles-index-es-js" */),
  "component---src-pages-cosmetics-testing-hair-density-increase-volumizing-effect-index-en-js": () => import("./../../../src/pages/cosmetics-testing/hair-density-increase-volumizing-effect/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-density-increase-volumizing-effect-index-en-js" */),
  "component---src-pages-cosmetics-testing-hair-density-increase-volumizing-effect-index-es-js": () => import("./../../../src/pages/cosmetics-testing/hair-density-increase-volumizing-effect/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-density-increase-volumizing-effect-index-es-js" */),
  "component---src-pages-cosmetics-testing-hair-growth-anti-hair-loss-index-en-js": () => import("./../../../src/pages/cosmetics-testing/hair-growth-anti-hair-loss/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-growth-anti-hair-loss-index-en-js" */),
  "component---src-pages-cosmetics-testing-hair-growth-anti-hair-loss-index-es-js": () => import("./../../../src/pages/cosmetics-testing/hair-growth-anti-hair-loss/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-growth-anti-hair-loss-index-es-js" */),
  "component---src-pages-cosmetics-testing-hair-thickness-increase-index-en-js": () => import("./../../../src/pages/cosmetics-testing/hair-thickness-increase/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-thickness-increase-index-en-js" */),
  "component---src-pages-cosmetics-testing-hair-thickness-increase-index-es-js": () => import("./../../../src/pages/cosmetics-testing/hair-thickness-increase/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-hair-thickness-increase-index-es-js" */),
  "component---src-pages-cosmetics-testing-index-en-js": () => import("./../../../src/pages/cosmetics-testing/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-index-en-js" */),
  "component---src-pages-cosmetics-testing-index-es-js": () => import("./../../../src/pages/cosmetics-testing/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-index-es-js" */),
  "component---src-pages-cosmetics-testing-ingredient-toxicological-profiles-index-en-js": () => import("./../../../src/pages/cosmetics-testing/ingredient-toxicological-profiles/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-ingredient-toxicological-profiles-index-en-js" */),
  "component---src-pages-cosmetics-testing-ingredient-toxicological-profiles-index-es-js": () => import("./../../../src/pages/cosmetics-testing/ingredient-toxicological-profiles/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-ingredient-toxicological-profiles-index-es-js" */),
  "component---src-pages-cosmetics-testing-microbiology-testing-index-en-js": () => import("./../../../src/pages/cosmetics-testing/microbiology-testing/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-microbiology-testing-index-en-js" */),
  "component---src-pages-cosmetics-testing-microbiology-testing-index-es-js": () => import("./../../../src/pages/cosmetics-testing/microbiology-testing/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-microbiology-testing-index-es-js" */),
  "component---src-pages-cosmetics-testing-moisturizing-hydration-index-en-js": () => import("./../../../src/pages/cosmetics-testing/moisturizing-hydration/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-moisturizing-hydration-index-en-js" */),
  "component---src-pages-cosmetics-testing-moisturizing-hydration-index-es-js": () => import("./../../../src/pages/cosmetics-testing/moisturizing-hydration/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-moisturizing-hydration-index-es-js" */),
  "component---src-pages-cosmetics-testing-pore-size-reduction-index-en-js": () => import("./../../../src/pages/cosmetics-testing/pore-size-reduction/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-pore-size-reduction-index-en-js" */),
  "component---src-pages-cosmetics-testing-pore-size-reduction-index-es-js": () => import("./../../../src/pages/cosmetics-testing/pore-size-reduction/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-pore-size-reduction-index-es-js" */),
  "component---src-pages-cosmetics-testing-redness-reduction-index-en-js": () => import("./../../../src/pages/cosmetics-testing/redness-reduction/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-redness-reduction-index-en-js" */),
  "component---src-pages-cosmetics-testing-redness-reduction-index-es-js": () => import("./../../../src/pages/cosmetics-testing/redness-reduction/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-redness-reduction-index-es-js" */),
  "component---src-pages-cosmetics-testing-rejuvenating-firming-lifting-index-en-js": () => import("./../../../src/pages/cosmetics-testing/rejuvenating-firming-lifting/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-rejuvenating-firming-lifting-index-en-js" */),
  "component---src-pages-cosmetics-testing-rejuvenating-firming-lifting-index-es-js": () => import("./../../../src/pages/cosmetics-testing/rejuvenating-firming-lifting/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-rejuvenating-firming-lifting-index-es-js" */),
  "component---src-pages-cosmetics-testing-safety-data-sheet-sds-index-en-js": () => import("./../../../src/pages/cosmetics-testing/safety-data-sheet-sds/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-safety-data-sheet-sds-index-en-js" */),
  "component---src-pages-cosmetics-testing-safety-data-sheet-sds-index-es-js": () => import("./../../../src/pages/cosmetics-testing/safety-data-sheet-sds/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-safety-data-sheet-sds-index-es-js" */),
  "component---src-pages-cosmetics-testing-sebum-regulating-mattifying-effect-index-en-js": () => import("./../../../src/pages/cosmetics-testing/sebum-regulating-mattifying-effect/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-sebum-regulating-mattifying-effect-index-en-js" */),
  "component---src-pages-cosmetics-testing-sebum-regulating-mattifying-effect-index-es-js": () => import("./../../../src/pages/cosmetics-testing/sebum-regulating-mattifying-effect/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-sebum-regulating-mattifying-effect-index-es-js" */),
  "component---src-pages-cosmetics-testing-self-tanning-products-index-en-js": () => import("./../../../src/pages/cosmetics-testing/self-tanning-products/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-self-tanning-products-index-en-js" */),
  "component---src-pages-cosmetics-testing-self-tanning-products-index-es-js": () => import("./../../../src/pages/cosmetics-testing/self-tanning-products/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-self-tanning-products-index-es-js" */),
  "component---src-pages-cosmetics-testing-skin-barrier-improving-index-en-js": () => import("./../../../src/pages/cosmetics-testing/skin-barrier-improving/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-skin-barrier-improving-index-en-js" */),
  "component---src-pages-cosmetics-testing-skin-barrier-improving-index-es-js": () => import("./../../../src/pages/cosmetics-testing/skin-barrier-improving/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-skin-barrier-improving-index-es-js" */),
  "component---src-pages-cosmetics-testing-skin-sensitivity-evaluation-anti-irritating-index-en-js": () => import("./../../../src/pages/cosmetics-testing/skin-sensitivity-evaluation-anti-irritating/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-skin-sensitivity-evaluation-anti-irritating-index-en-js" */),
  "component---src-pages-cosmetics-testing-skin-sensitivity-evaluation-anti-irritating-index-es-js": () => import("./../../../src/pages/cosmetics-testing/skin-sensitivity-evaluation-anti-irritating/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-skin-sensitivity-evaluation-anti-irritating-index-es-js" */),
  "component---src-pages-cosmetics-testing-smoothing-index-en-js": () => import("./../../../src/pages/cosmetics-testing/smoothing/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-smoothing-index-en-js" */),
  "component---src-pages-cosmetics-testing-smoothing-index-es-js": () => import("./../../../src/pages/cosmetics-testing/smoothing/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-smoothing-index-es-js" */),
  "component---src-pages-cosmetics-testing-split-ends-repairing-index-en-js": () => import("./../../../src/pages/cosmetics-testing/split-ends-repairing/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-split-ends-repairing-index-en-js" */),
  "component---src-pages-cosmetics-testing-split-ends-repairing-index-es-js": () => import("./../../../src/pages/cosmetics-testing/split-ends-repairing/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-split-ends-repairing-index-es-js" */),
  "component---src-pages-cosmetics-testing-sun-protection-factor-determination-spf-index-en-js": () => import("./../../../src/pages/cosmetics-testing/sun-protection-factor-determination-spf/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-sun-protection-factor-determination-spf-index-en-js" */),
  "component---src-pages-cosmetics-testing-sun-protection-factor-determination-spf-index-es-js": () => import("./../../../src/pages/cosmetics-testing/sun-protection-factor-determination-spf/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-sun-protection-factor-determination-spf-index-es-js" */),
  "component---src-pages-cosmetics-testing-teeth-whitening-index-en-js": () => import("./../../../src/pages/cosmetics-testing/teeth-whitening/index.en.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-teeth-whitening-index-en-js" */),
  "component---src-pages-cosmetics-testing-teeth-whitening-index-es-js": () => import("./../../../src/pages/cosmetics-testing/teeth-whitening/index.es.js" /* webpackChunkName: "component---src-pages-cosmetics-testing-teeth-whitening-index-es-js" */),
  "component---src-pages-eu-cosmetic-compliance-checklist-index-en-js": () => import("./../../../src/pages/eu-cosmetic-compliance-checklist/index.en.js" /* webpackChunkName: "component---src-pages-eu-cosmetic-compliance-checklist-index-en-js" */),
  "component---src-pages-eu-cosmetic-compliance-checklist-index-es-js": () => import("./../../../src/pages/eu-cosmetic-compliance-checklist/index.es.js" /* webpackChunkName: "component---src-pages-eu-cosmetic-compliance-checklist-index-es-js" */),
  "component---src-pages-faq-cosmetics-index-en-js": () => import("./../../../src/pages/faq-cosmetics/index.en.js" /* webpackChunkName: "component---src-pages-faq-cosmetics-index-en-js" */),
  "component---src-pages-faq-cosmetics-index-es-js": () => import("./../../../src/pages/faq-cosmetics/index.es.js" /* webpackChunkName: "component---src-pages-faq-cosmetics-index-es-js" */),
  "component---src-pages-formula-compliance-index-en-js": () => import("./../../../src/pages/formula-compliance/index.en.js" /* webpackChunkName: "component---src-pages-formula-compliance-index-en-js" */),
  "component---src-pages-formula-compliance-index-es-js": () => import("./../../../src/pages/formula-compliance/index.es.js" /* webpackChunkName: "component---src-pages-formula-compliance-index-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-international-services-asean-index-en-js": () => import("./../../../src/pages/international-services/asean/index.en.js" /* webpackChunkName: "component---src-pages-international-services-asean-index-en-js" */),
  "component---src-pages-international-services-asean-index-es-js": () => import("./../../../src/pages/international-services/asean/index.es.js" /* webpackChunkName: "component---src-pages-international-services-asean-index-es-js" */),
  "component---src-pages-international-services-canada-index-en-js": () => import("./../../../src/pages/international-services/canada/index.en.js" /* webpackChunkName: "component---src-pages-international-services-canada-index-en-js" */),
  "component---src-pages-international-services-canada-index-es-js": () => import("./../../../src/pages/international-services/canada/index.es.js" /* webpackChunkName: "component---src-pages-international-services-canada-index-es-js" */),
  "component---src-pages-international-services-china-index-en-js": () => import("./../../../src/pages/international-services/china/index.en.js" /* webpackChunkName: "component---src-pages-international-services-china-index-en-js" */),
  "component---src-pages-international-services-china-index-es-js": () => import("./../../../src/pages/international-services/china/index.es.js" /* webpackChunkName: "component---src-pages-international-services-china-index-es-js" */),
  "component---src-pages-international-services-other-countries-index-en-js": () => import("./../../../src/pages/international-services/other-countries/index.en.js" /* webpackChunkName: "component---src-pages-international-services-other-countries-index-en-js" */),
  "component---src-pages-international-services-other-countries-index-es-js": () => import("./../../../src/pages/international-services/other-countries/index.es.js" /* webpackChunkName: "component---src-pages-international-services-other-countries-index-es-js" */),
  "component---src-pages-international-services-saudi-arabia-index-en-js": () => import("./../../../src/pages/international-services/saudi-arabia/index.en.js" /* webpackChunkName: "component---src-pages-international-services-saudi-arabia-index-en-js" */),
  "component---src-pages-international-services-saudi-arabia-index-es-js": () => import("./../../../src/pages/international-services/saudi-arabia/index.es.js" /* webpackChunkName: "component---src-pages-international-services-saudi-arabia-index-es-js" */),
  "component---src-pages-international-services-uae-index-en-js": () => import("./../../../src/pages/international-services/uae/index.en.js" /* webpackChunkName: "component---src-pages-international-services-uae-index-en-js" */),
  "component---src-pages-international-services-uae-index-es-js": () => import("./../../../src/pages/international-services/uae/index.es.js" /* webpackChunkName: "component---src-pages-international-services-uae-index-es-js" */),
  "component---src-pages-international-services-usa-index-en-js": () => import("./../../../src/pages/international-services/usa/index.en.js" /* webpackChunkName: "component---src-pages-international-services-usa-index-en-js" */),
  "component---src-pages-international-services-usa-index-es-js": () => import("./../../../src/pages/international-services/usa/index.es.js" /* webpackChunkName: "component---src-pages-international-services-usa-index-es-js" */),
  "component---src-pages-international-services-usa-otc-index-en-js": () => import("./../../../src/pages/international-services/usa-otc/index.en.js" /* webpackChunkName: "component---src-pages-international-services-usa-otc-index-en-js" */),
  "component---src-pages-international-services-usa-otc-index-es-js": () => import("./../../../src/pages/international-services/usa-otc/index.es.js" /* webpackChunkName: "component---src-pages-international-services-usa-otc-index-es-js" */),
  "component---src-pages-pi-finstructions-index-en-js": () => import("./../../../src/pages/PIFinstructions/index.en.js" /* webpackChunkName: "component---src-pages-pi-finstructions-index-en-js" */),
  "component---src-pages-pi-finstructions-index-es-js": () => import("./../../../src/pages/PIFinstructions/index.es.js" /* webpackChunkName: "component---src-pages-pi-finstructions-index-es-js" */),
  "component---src-pages-privacy-policy-index-en-js": () => import("./../../../src/pages/privacy-policy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-en-js" */),
  "component---src-pages-privacy-policy-index-es-js": () => import("./../../../src/pages/privacy-policy/index.es.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-es-js" */),
  "component---src-pages-report-adverse-event-index-en-js": () => import("./../../../src/pages/report-adverse-event/index.en.js" /* webpackChunkName: "component---src-pages-report-adverse-event-index-en-js" */),
  "component---src-pages-report-adverse-event-index-es-js": () => import("./../../../src/pages/report-adverse-event/index.es.js" /* webpackChunkName: "component---src-pages-report-adverse-event-index-es-js" */),
  "component---src-pages-shop-index-en-js": () => import("./../../../src/pages/shop/index.en.js" /* webpackChunkName: "component---src-pages-shop-index-en-js" */),
  "component---src-pages-shop-index-es-js": () => import("./../../../src/pages/shop/index.es.js" /* webpackChunkName: "component---src-pages-shop-index-es-js" */),
  "component---src-pages-us-cosmetics-regulation-mocra-index-en-js": () => import("./../../../src/pages/us-cosmetics-regulation-mocra/index.en.js" /* webpackChunkName: "component---src-pages-us-cosmetics-regulation-mocra-index-en-js" */),
  "component---src-pages-us-cosmetics-regulation-mocra-index-es-js": () => import("./../../../src/pages/us-cosmetics-regulation-mocra/index.es.js" /* webpackChunkName: "component---src-pages-us-cosmetics-regulation-mocra-index-es-js" */)
}

