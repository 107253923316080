import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import React from 'react'
import Globe from './icons/globe'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

const LanguageSwitcher = () => {
  const location = useLocation()
  const pathname = location.pathname
  const pathSegments = pathname?.split('/').filter(Boolean)
  const pathTarget =
    pathSegments[0] && pathSegments[0].length === 2
      ? pathSegments.slice(1).join('/')
      : pathSegments.slice(0).join('/')

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className='p-4 rounded-full hover:bg-gray-100'>
          <Globe />
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuPortal>
        <DropdownMenuContent
          className='min-w-[220px] rounded-xl bg-white p-[5px] shadow-xl'
          sideOffset={5}
        >
          <DropdownMenuItem className='flex items-center p-2 hover:bg-[#0091ca] hover:text-white rounded-md'>
            <Link to={`/${pathTarget || ''}`}>🇬🇧 English</Link>
          </DropdownMenuItem>
          <DropdownMenuItem className='flex items-center p-2 hover:bg-[#0091ca] hover:text-white rounded-md'>
            <Link to={`/es/${pathTarget || ''}`}>🇪🇸 Español</Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}

export default LanguageSwitcher
