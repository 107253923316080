import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import { theme } from 'constants/ui/theme'

import GlobalStyles from 'styles/GlobalStyles'
import { PageLayout } from 'styles/PageLayout'

import Header from './Header'
import Footer from './Footer'
import Cookies from './Cookies'

/**
 * The page layout including all global styles and theme provider,
 * used as a wrapper in gatsby-browser and gatsby-ssr.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @returns {StatelessComponent}
 */
const Page = ({ children, location }) => {
  const pathname = location ? location.pathname : ''
  const pathSegments = pathname?.split('/').filter(Boolean)
  const langKey = pathSegments[0] && pathSegments[0].length === 2 ? pathSegments[0] : 'en'

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Header langKey={langKey} />
        <PageLayout>{children}</PageLayout>
        <Cookies langKey={langKey} />
        <Footer langKey={langKey} />
      </ThemeProvider>
    </>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Page
