import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import Globe from './icons/globe'
import { Link } from 'gatsby'

const LanguageSwitcherMobile = () => {
  const location = useLocation()
  const pathname = location.pathname
  const pathSegments = pathname?.split('/').filter(Boolean)
  const pathTarget =
    pathSegments[0] && pathSegments[0].length === 2
      ? pathSegments.slice(1).join('/')
      : pathSegments.slice(0).join('/')

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <button className='p-4 rounded-full hover:bg-gray-100'>
          <Globe />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className='rounded-lg border bg-white mx-4 p-2 space-y-2'>
          <DropdownMenuItem>
            <Link to={`/${pathTarget || ''}`}>🇬🇧 English</Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link to={`/es/${pathTarget || ''}`}>🇪🇸 Español</Link>
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default LanguageSwitcherMobile
