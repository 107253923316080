import React, { useState } from 'react'
import { Link } from 'gatsby'

import { theme } from 'constants/ui/theme'

import Logo from './icons/logo'
import CaretRight from './icons/caret-right'
import CaretDown from './icons/caret-down'
import Close from './icons/close'
import Menu from './icons/menu'

import { Container } from 'styles/PageLayout'
import { ParagraphL, ParagraphS, ParagraphXS } from 'styles/Typography'
import { HeaderStyled, MobileNav } from 'styles/components/HeaderStyled'
import { ButtonPrimary } from 'styles/components/ButtonStyled'
import LanguageSwitcher from './LanguageSwitcher'
import LanguageSwitcherMobile from './LanguageSwitcherMobile'

const t = {
  en: {
    aboutUs: 'About us',
    news: 'News',
    regulatoryUpdates: 'Regulatory updates',
    faq: 'FAQ',
    shop: 'Shop',
    contact: 'Contact us',
    euAndUkRegulatory: 'EU & UK Regulatory',
    otherMarketsRegulatory: 'Other Markets Regulatory',
    usa: 'USA',
    canada: 'Canada',
    china: 'China',
    saudi: 'Saudi Arabia',
    otherCountries: 'Other Countries',
    safetyTesting: 'Safety Testing',
    stabilityAndCompatibility: 'Stability and Compatibility',
    challengeTest: 'Challenge Test',
    microbiologyTesting: 'Microbiology Testing',
    cpsr: 'CPSR',
    sds: 'SDS',
    patchTest: 'Patch Test',
    spf: 'SPF',
    efficacyTesting: 'Efficacy testing',
    ingredients: 'Ingredients',
    sds: 'SDS',
    toxProfile: 'Tox profile',
    links: {
      homePage: '/',
      aboutUs: '/about-us',
      news: 'https://news.ceway.eu',
      regulatoryUpdates: 'https://news.ceway.eu/regulatory-updates',
      faq: '/faq-cosmetics',
      shop: '/shop',
      contact: '/contact',
      euAndUkRegulatory: '/cosmetics-regulatory',
      usa: '/international-services/usa',
      usaOtc: '/international-services/usa-otc',
      asean: '/international-services/asean',
      canada: '/international-services/canada',
      china: '/international-services/china',
      saudi: '/international-services/saudi-arabia',
      uae: '/international-services/uae',
      otherCountries: '/international-services/other-countries',
      safetyTesting: '/cosmetics-regulatory-services/stability-compatibility-test',
      stabilityAndCompatibility: '/cosmetics-regulatory-services/stability-compatibility-test',
      challengeTest: '/cosmetics-regulatory-services/cosmetics-challenge-test',
      microbiologyTesting: '/cosmetics-testing/microbiology-testing',
      cpsr: '/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr',
      sds: '/cosmetics-testing/safety-data-sheet-sds',
      patchTest: '/cosmetics-testing/cosmetics-patch-test',
      spf: '/cosmetics-testing/sun-protection-factor-determination-spf',
      efficacyTesting: '/cosmetics-testing',
      ingredients: '/cosmetics-testing/ingredient-toxicological-profiles',
      toxProfile: '/cosmetics-testing/ingredient-toxicological-profiles',
    },
  },
  es: {
    aboutUs: 'Sobre nosotros',
    news: 'Noticias',
    regulatoryUpdates: 'Actualizaciones regulatorias',
    faq: 'FAQ',
    shop: 'Tienda',
    contact: 'Contáctanos',
    euAndUkRegulatory: 'Regulación de la UE y el Reino Unido',
    otherMarketsRegulatory: 'Regulación en otros mercados',
    usa: 'EE. UU.',
    canada: 'Canadá',
    china: 'China',
    saudi: 'Arabia Saudita',
    otherCountries: 'Otros países',
    safetyTesting: 'Pruebas de seguridad',
    stabilityAndCompatibility: 'Estabilidad y compatibilidad',
    challengeTest: 'Prueba de desafío',
    microbiologyTesting: 'Pruebas de microbiología',
    cpsr: 'CPSR',
    sds: 'SDS',
    patchTest: 'Prueba de parche',
    spf: 'SPF',
    efficacyTesting: 'Pruebas de eficacia',
    ingredients: 'Ingredientes',
    sds: 'SDS',
    toxProfile: 'Perfil de tox',
    links: {
      homePage: '/es',
      aboutUs: '/es/about-us',
      news: 'https://news.ceway.eu',
      regulatoryUpdates: 'https://news.ceway.eu/regulatory-updates',
      faq: '/es/faq-cosmetics',
      shop: '/es/shop',
      contact: '/es/contact',
      euAndUkRegulatory: '/es/cosmetics-regulatory',
      usa: '/es/international-services/usa',
      usaOtc: '/es/international-services/usa-otc',
      asean: '/es/international-services/asean',
      canada: '/es/international-services/canada',
      china: '/es/international-services/china',
      saudi: '/es/international-services/saudi-arabia',
      uae: '/es/international-services/uae',
      otherCountries: '/es/international-services/other-countries',
      safetyTesting: '/es/cosmetics-regulatory-services/stability-compatibility-test',
      stabilityAndCompatibility: '/es/cosmetics-regulatory-services/stability-compatibility-test',
      challengeTest: '/es/cosmetics-regulatory-services/cosmetics-challenge-test',
      microbiologyTesting: '/es/cosmetics-testing/microbiology-testing',
      cpsr: '/es/cosmetics-regulatory-services/cosmetics-safety-assessment-cpsr',
      sds: '/es/cosmetics-testing/safety-data-sheet-sds',
      patchTest: '/es/cosmetics-testing/cosmetics-patch-test',
      spf: '/es/cosmetics-testing/sun-protection-factor-determination-spf',
      efficacyTesting: '/es/cosmetics-testing',
      ingredients: '/es/cosmetics-testing/ingredient-toxicological-profiles',
      toxProfile: '/es/cosmetics-testing/ingredient-toxicological-profiles',
    },
  },
}

/**
 * The main header of the website.
 * @returns {StatelessComponent}
 */
const Header = ({ langKey = 'en' }) => {
  const lang = Object.keys(t).includes(langKey) ? langKey : 'en'

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [openRegulatoryDropdown, setOpenRegulatoryDropdown] = useState(false)
  const [openSafetyDropdown, setOpenSafetyDropdown] = useState(false)
  const [openIngredientsDropdown, setOpenIngredientsDropdown] = useState(false)

  const toggleMobileNav = () => {
    document.body.classList.toggle('no-scroll')
    setIsMobileNavOpen(!isMobileNavOpen)
  }

  const closeMobileNav = () => {
    document.body.classList.toggle('no-scroll')
    setIsMobileNavOpen(false)
  }

  return (
    <>
      <HeaderStyled isOpen={isMobileNavOpen}>
        <Container column>
          <div className='upper'>
            <Link to={t[lang]?.links?.homePage}>
              <div className='logo'>
                <Logo />
              </div>
            </Link>
            <div className='links hide-sm'>
              <Link to={t[lang]?.links?.aboutUs} className='link-item'>
                <ParagraphS weight={500}>{t[lang]?.aboutUs}</ParagraphS>
              </Link>
              <a href='https://news.ceway.eu' className='link-item'>
                <ParagraphS weight={500}>{t[lang]?.news}</ParagraphS>
              </a>
              <a href='https://news.ceway.eu/regulatory-updates' className='link-item'>
                <ParagraphS weight={500}>{t[lang]?.regulatoryUpdates}</ParagraphS>
              </a>
              <Link to={t[lang]?.links?.faq} className='link-item'>
                <ParagraphS weight={500}>{t[lang]?.faq}</ParagraphS>
              </Link>
              <Link to={t[lang]?.links?.shop} className='link-item'>
                <ParagraphS weight={500}>{t[lang]?.shop}</ParagraphS>
              </Link>
            </div>
            <div className='hide-sm flex gap-4 items-center ml-auto'>
              <LanguageSwitcher />
              <Link to={t[lang]?.links?.contact} className='contact-btn'>
                <ButtonPrimary>
                  <CaretRight /> {t[lang]?.contact}
                </ButtonPrimary>
              </Link>
            </div>
            <button className='menu-btn hide-lg' onClick={toggleMobileNav}>
              <Menu className={`menu ${isMobileNavOpen ? 'open' : ''}`} />
              <Close className={`close ${isMobileNavOpen ? 'open' : ''}`} />
            </button>
          </div>

          <div className='lower hide-sm'>
            <div className='links'>
              <Link to={t[lang]?.links?.euAndUkRegulatory} className='link-item'>
                <ParagraphS weight={500}>{t[lang]?.euAndUkRegulatory}</ParagraphS>
              </Link>
              <div className='link-item' id='other-markets-regulatory'>
                <ParagraphS weight={500}>
                  {t[lang]?.otherMarketsRegulatory} <CaretDown />
                </ParagraphS>
                <div className='dropdown' id='other-markets-regulatory-dropdown'>
                  <Link to={t[lang]?.links?.usa} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      USA
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.usaOtc} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      USA OTC
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.canada} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      Canada
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.asean} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      ASEAN
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.china} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      China
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.saudi} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      {t[lang]?.saudi}
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.uae} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      UAE
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.otherCountries} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      {t[lang]?.otherCountries}
                    </ParagraphXS>
                  </Link>
                </div>
              </div>
              <div className='link-item' id='safety-testing'>
                <ParagraphS weight={500}>
                  {t[lang]?.safetyTesting} <CaretDown />
                </ParagraphS>
                <div className='dropdown' id='safety-testing-dropdown'>
                  <Link to={t[lang]?.links?.stabilityAndCompatibility} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      {t[lang]?.stabilityAndCompatibility}
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.challengeTest} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      {t[lang]?.challengeTest}
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.microbiologyTesting} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      {t[lang]?.microbiologyTesting}
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.cpsr} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      CPSR
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.sds} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      SDS
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.patchTest} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      {t[lang]?.patchTest}
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.spf} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      SPF
                    </ParagraphXS>
                  </Link>
                </div>
              </div>
              <Link to={t[lang]?.links?.efficacyTesting} className='link-item'>
                <ParagraphS weight={500}>{t[lang]?.efficacyTesting}</ParagraphS>
              </Link>
              <div className='link-item' id='ingredients'>
                <ParagraphS weight={500}>
                  {t[lang]?.ingredients} <CaretDown />
                </ParagraphS>
                <div className='dropdown' id='ingredients-dropdown'>
                  <Link to={t[lang]?.links?.sds} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      SDS
                    </ParagraphXS>
                  </Link>
                  <Link to={t[lang]?.links?.toxProfile} className='dropdown-item'>
                    <ParagraphXS weight={500} color={theme.textSecondary}>
                      {t[lang]?.toxProfile}
                    </ParagraphXS>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </HeaderStyled>

      <MobileNav isOpen={isMobileNavOpen}>
        <div className='links border-bottom'>
          <Link
            to={t[lang]?.links?.euAndUkRegulatory}
            className='link-item'
            onClick={closeMobileNav}
          >
            <ParagraphL weight={500}>{t[lang]?.euAndUkRegulatory}</ParagraphL>
          </Link>
          <div
            className='link-item'
            onClick={() => setOpenRegulatoryDropdown(!openRegulatoryDropdown)}
          >
            <ParagraphL weight={500}>
              {t[lang]?.otherMarketsRegulatory}{' '}
              <CaretDown className={`icon ${openRegulatoryDropdown ? 'rotate' : ''}`} />
            </ParagraphL>
            <div className={`dropdown dropdown-1 ${openRegulatoryDropdown ? 'open' : ''}`}>
              <Link
                to={t[lang]?.links?.usa}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  USA
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.usaOtc}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  USA OTC
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.canada}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.canada}
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.asean}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  ASEAN
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.china}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.china}
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.saudi}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.saudi}
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.uae}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  UAE
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.otherCountries}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenRegulatoryDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.otherCountries}
                </ParagraphL>
              </Link>
            </div>
          </div>
          <div className='link-item' onClick={() => setOpenSafetyDropdown(!openSafetyDropdown)}>
            <ParagraphL weight={500}>
              {t[lang]?.safetyTesting}{' '}
              <CaretDown className={`icon ${openSafetyDropdown ? 'rotate' : ''}`} />
            </ParagraphL>
            <div className={`dropdown dropdown-2 ${openSafetyDropdown ? 'open' : ''}`}>
              <Link
                to={t[lang]?.links?.stabilityAndCompatibility}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.stabilityAndCompatibility}
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.challengeTest}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.challengeTest}
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.microbiologyTesting}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.microbiologyTesting}
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.cpsr}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  CPSR
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.sds}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  SDS
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.patchTest}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.patchTest}
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.spf}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenSafetyDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  SPF
                </ParagraphL>
              </Link>
            </div>
          </div>
          <Link to={t[lang]?.links?.efficacyTesting} className='link-item' onClick={closeMobileNav}>
            <ParagraphL weight={500}>{t[lang]?.efficacyTesting}</ParagraphL>
          </Link>
          <div
            className='link-item'
            onClick={() => setOpenIngredientsDropdown(!openIngredientsDropdown)}
          >
            <ParagraphL weight={500}>
              {t[lang]?.ingredients}{' '}
              <CaretDown className={`icon ${openIngredientsDropdown ? 'rotate' : ''}`} />
            </ParagraphL>
            <div className={`dropdown dropdown-3 ${openIngredientsDropdown ? 'open' : ''}`}>
              <Link
                to={t[lang]?.links?.sds}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenIngredientsDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  SDS
                </ParagraphL>
              </Link>
              <Link
                to={t[lang]?.links?.toxProfile}
                className='dropdown-item'
                onClick={() => {
                  closeMobileNav()
                  setOpenIngredientsDropdown(false)
                }}
              >
                <ParagraphL weight={500} color={theme.textSecondary}>
                  {t[lang]?.toxProfile}
                </ParagraphL>
              </Link>
            </div>
          </div>
        </div>
        <div className='links border-bottom'>
          <Link to={t[lang]?.links?.aboutUs} className='link-item' onClick={closeMobileNav}>
            <ParagraphL weight={500}>{t[lang]?.aboutUs}</ParagraphL>
          </Link>
          <a href='https://news.ceway.eu' className='link-item' onClick={closeMobileNav}>
            <ParagraphL weight={500}>News</ParagraphL>
          </a>
          <a href='https://news.ceway.eu/regulatory-updates' className='link-item'>
            <ParagraphS weight={500}>{t[lang]?.regulatoryUpdates}</ParagraphS>
          </a>
          <Link to={t[lang]?.links?.faq} className='link-item' onClick={closeMobileNav}>
            <ParagraphL weight={500}>FAQ</ParagraphL>
          </Link>
          <Link to={t[lang]?.links?.shop} className='link-item' onClick={closeMobileNav}>
            <ParagraphL weight={500}>{t[lang]?.shop}</ParagraphL>
          </Link>
        </div>
        <div className='btn items-center !justify-between'>
          <LanguageSwitcherMobile />
          <Link to={t[lang]?.links?.contact}>
            <ButtonPrimary onClick={closeMobileNav}>
              <CaretRight /> {t[lang]?.contact}
            </ButtonPrimary>
          </Link>
        </div>
      </MobileNav>
    </>
  )
}

export default Header
